<template>
    <q-layout view="hHh Lpr lFf" >
        <q-page-container>
            <q-page class="row justify-center items-center bg-grey-2">
                <div class="text-center q-ml-sm q-pa-md">
                    <q-img v-if="!is_mobile" src="/static/frontimgs/emis.jpg" style="width: 785px"></q-img>
                </div>
                <q-card >                   
                    <q-card-section> 
                        <q-toolbar-title v-if="$q.screen.gt.sm" shrink class="row items-center no-wrap" >
                            <q-img src="/static/frontimgs/logo.png" style="width: 80px"></q-img>
                            <span class="q-ml-sm text-grey-8 text-weight-bold">eMIS服务</span>
                        </q-toolbar-title>                   
                        <div >
                            欢迎登录！
                        </div>
                    </q-card-section>
                    <q-separator inset> </q-separator>
                    <q-card-section v-if="getValidate.type=='form'">
                        <div class="text-negative">{{ getValidate.msg }}</div>
                    </q-card-section>
                    <q-card-section>
                        <q-form class="q-gutter-sm" style="max-width:270px"> 
                            <q-input dense filled name="username" v-model="login_data.username" label="登录名"  
                            :error="getValidate.type=='username'" :error-message="getValidate.msg">
                                <template v-slot:before>
                                <q-icon name="fas fa-user" ></q-icon>
                                </template>
                            </q-input> 
                            <q-input dense filled name="password" v-model="login_data.password" label="密码" type="password" 
                            :error="getValidate.type=='password'" :error-message="getValidate.msg">
                                <template v-slot:before>
                                <q-icon name="fas fa-lock" ></q-icon>
                                </template>
                            </q-input>
                            <q-input dense filled name="captcha" v-model="login_data.captcha" label="验证码"  
                            :error="getValidate.type=='captcha'" :error-message="getValidate.msg">
                                <template v-slot:before>
                                <q-icon name="fas fa-key" class="q-mr-xs"></q-icon>
                                <q-img :src="captcha_data.image_url" class="cursor-pointer"
                                    alt="验证码" style="width:78px" @click="get_captcha()">
                                        <q-tooltip>点击更换验证码</q-tooltip>
                                    </q-img>
                                </template>
                                
                            </q-input>
                            <q-toggle color="primary" size="sm" v-model="save" label="记忆密码"></q-toggle>
                        </q-form>
                    </q-card-section>                  
                    <q-card-actions class="row justify-center">
                        <q-btn color="primary" size="md" label="登录系统" icon="fas fa-sign-in-alt" @click="login" class="q-mb-md"/>                    
                    </q-card-actions>
                </q-card>
            </q-page>
        </q-page-container>
    </q-layout>
</template>
<script>
import { ref } from 'vue'
import { useStore, mapGetters } from 'vuex'
import API from '../apis'
import MobileDetect from "mobile-detect"

export default {
    name: 'login-view',
    computed:{
        ...mapGetters('page', ['getValidate', 'getResponse']),
        ...mapGetters('user', ['isLogin'])
    },
    watch:{
        getResponse(v1){
            if(v1){
                let t = v1.status=='positive'?1:3000
                this.$q.notify({
                progress: true,
                timeout:t,                
                type: v1.status,
                multiLine: true,
                message: v1.status_code>400 ? `${v1.title}${v1.status_text}${v1.status_code}，${JSON.stringify(v1.data)}` : `${v1.title}：${v1.status_text}。`
                })
            }
        },
        isLogin(v){
            if(v){
                const curr = localStorage.getItem('preRouteName')
                if (curr){          
                    this.$router.push({name:curr})
                } else {
                    this.$router.push({name:'home'}) 
                }
            }
        },
        save(v){
            if(v){
                this.$q.notify({
                type: 'warning',
                message: '开启记忆密码会有安全风险，请注意保护您的设备不被盗用。'
                })
            }
        }
    },
    setup(){
        const store = useStore()
        let login_data = ref({})
        
        let save = ref(false)

        const md = new MobileDetect(window.navigator.userAgent);
        let is_mobile = false

        if (md.mobile() || md.tablet()) {
          is_mobile = true
        } else {
          is_mobile = false
        }




        if(localStorage.getItem('saveLogin')){
            save.value = true
            login_data.value = localStorage.getItem('loginFormData') ? JSON.parse(localStorage.getItem('loginFormData')) : {}
        }
        let captcha_data = ref({})
        function get_captcha(){      
            API.getCaptcha().then(res=>{
                captcha_data.value = res.data                
            })
        }
        function savedata(){
            let save_data = {username: login_data.value.username, password: login_data.value.password}
            if(save.value){
                localStorage.setItem('saveLogin', 1)
                localStorage.setItem('loginFormData', JSON.stringify(save_data))
            }else{
                localStorage.removeItem('saveLogin')
                localStorage.removeItem('loginFormData')
            }
        }
        function login(){
            if(!login_data.value.username){ store.dispatch('page/error',{msg:"请填写登录名",type:'username'}); return}
            if(!login_data.value.password){ store.dispatch('page/error',{msg:"请填写登录密码",type:'password'}); return}
            if(!login_data.value.captcha){ store.dispatch('page/error',{msg:"请填写验证码",type:'captcha'}); return}
            savedata()
            store.dispatch('user/login',{username: login_data.value.username, password: login_data.value.password,captcha:login_data.value.captcha, captcha_hash: captcha_data.value.hashkey})
        }        
        get_captcha()
        return {
            save,login_data,captcha_data,get_captcha,login, is_mobile
        }
    }
}
</script>